export default function useLocalStorage(initialValue, key) {
  return {
    get() {
      const storageVal = window.localStorage.getItem(key);
      try {
        return storageVal ? JSON.parse(storageVal) : initialValue;
      } catch (e) {
        return storageVal || initialValue;
      }
    },
    set(value) {
      const valueToStore = typeof value === "object" ? JSON.stringify(value) : value;
      window.localStorage.setItem(key, valueToStore);
    }
  };
}
