<template>
  <div class="tickerwrapper">
    <div class="scroll-container">
      <div class="scroll-text" ref="scrollText">
        <span v-for="(item, index) in items" :key="`item-${index}`">{{ item }}</span>
        <span v-for="(item, index) in items" :key="`item-${index + items.length}`">{{ item }}</span>
        <span v-for="(item, index) in items" :key="`item-${index + items.length * 2}`">{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'campaignText',
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'info',
      validator: value => ['info', 'success', 'warning', 'error'].includes(value),
    },
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.initScrollingText();
  },
  methods: {
    initScrollingText() {
      if (!this.message) {
        console.error('Message prop is required');
        return;
      }

      this.items = this.message.split(';');

      this.$nextTick(() => {
        const scrollText = this.$refs.scrollText;
        const scrollWidth = scrollText.scrollWidth;
        const tickerWrapperWidth = scrollText.closest('.tickerwrapper').offsetWidth;

        // Adjust repeat count based on the width of the text and container
        const repeatCount = scrollWidth < tickerWrapperWidth ? 3 : Math.ceil(tickerWrapperWidth / scrollWidth) + 1;

        // Repeat the items as many times as needed
        this.items = new Array(repeatCount).fill(this.items).flat();

        // Set the CSS variables
        scrollText.style.setProperty('--scroll-width', `${scrollWidth}px`);

        // Pause animation on hover
        scrollText.addEventListener('mouseenter', () => {
          scrollText.style.animationPlayState = 'paused';
        });

        scrollText.addEventListener('mouseleave', () => {
          scrollText.style.animationPlayState = 'running';
        });
      });
    },
  },
};
</script>

<style scoped>
.tickerwrapper {
  position: relative;
  width: 100%;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
  background: #FD9B63;
}

.scroll-container {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.scroll-text {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-left 30s linear infinite;
}

.scroll-text span {
  padding-left: 20px;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1 * var(--scroll-width)));
  }
}
</style>
