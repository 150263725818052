<template>
  <v-app-bar flat app>
    <!-- Show Sidebar Button for Small Screens -->
    <v-app-bar-nav-icon v-if="currentUser" @click="toggleSidebar" class="hidden-md-and-up" :tabindex="-1"></v-app-bar-nav-icon>
  
    <v-app-bar-title>
      <router-link to="/" custom v-slot="{ navigate }">
        <div
          @click="navigate"
          @keypress.enter="navigate"
          role="link"
          style="cursor: pointer; display: flex; align-items: center;"
        >
          <span
            class="text-h5 font-weight-bold"
            :class="{
              'cyan--text text--darken-4': !darkTheme,
              'cyan--text text--darken-3': darkTheme,
            }"
          >
            {{ appTitle }}
          </span>
        </div>
      </router-link>
    </v-app-bar-title>

    <v-spacer></v-spacer>
  
    <!-- Algolia Search for Authenticated Users -->
    <v-toolbar-items v-if="isUserAuthenticated" class="mr-2 d-flex align-center">
        <AlgoliaSearch />
    </v-toolbar-items>

    <!-- Theme Toggle Text -->
    <v-toolbar-items v-if="currentUser">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" @click="toggleTheme" class="theme-toggle">
            <v-icon>{{ darkTheme ? 'mdi-white-balance-sunny' : 'mdi-weather-night' }}</v-icon>
          </span>
        </template>
        <span>{{ darkTheme ? 'Light-mode' : 'Dark-mode' }}</span>
      </v-tooltip>
    </v-toolbar-items>

    <!-- Admin Links -->
    <v-toolbar-items v-if="userClaims.admin" class="nav-link-group hidden-sm-and-down">
      <router-link v-for="item in menuItems" :key="item.title" :to="item.path" class="nav-link" :class="navLinkClass(item.path)">
        <!-- <v-icon left>{{ item.icon }}</v-icon> -->
        <span class="text-overline">{{ item.title }}</span>
      </router-link>
      <v-menu 
        offset-y 
        left 
        transition="slide-y-transition" 
        :elevation="3"
        v-model="isMenuOpen"
      >
        <template v-slot:activator="{ on, attrs }">
          <span 
            v-bind="attrs" 
            v-on="on"  
            class="nav-link" 
            style="cursor: pointer; display: flex; align-items: center;">
            <span class="text-overline">List</span>
            <v-icon :class="{ 'rotate-chevron': isMenuOpen }" small>mdi-chevron-down</v-icon>
          </span>
        </template>
        <v-list rounded dense>
          <v-list-item v-for="(item, idx) in listItems" :key="idx" :to="item.path" color="primary">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>

    <!-- Not Authenticated Buttons -->
    <v-toolbar-items v-if="!currentUser" class="nav-link-group">
      <router-link v-for="item in notAuthItems" :key="item.title" :to="item.path" class="nav-link" :class="navLinkClass(item.path)">
        <span class="text-overline">{{ item.title }}</span>
      </router-link>
    </v-toolbar-items>

    <v-toolbar-items class="hidden-sm-and-down" v-if="currentUser && !shouldDisplayContribute && !userClaims.admin">
      <router-link v-for="item in commonItems" :key="item.title" :to="item.path" class="nav-link" :class="navLinkClass(item.path)">
        <!-- <v-icon left>{{ item.icon }}</v-icon> -->
        <span class="text-overline">{{ item.title }}</span>
      </router-link>
    </v-toolbar-items>

    <!-- Contribute Items Menu for Authenticated Users -->
    <v-toolbar-items v-if="shouldDisplayContribute && !userClaims.admin" class="nav-link-group hidden-sm-and-down">
      <router-link 
        v-for="item in contributeItems" 
        :key="item.title" 
        :to="item.path" 
        :class="navLinkClass(item.path)"
        class="nav-link"
      >
        <span class="text-overline">{{ item.title }}</span>
      </router-link>
    </v-toolbar-items>

    <v-toolbar-items v-if="currentUser" class="d-flex align-center">
      <GeneralReport />
    </v-toolbar-items>

    <!-- Account Menu -->
    <v-toolbar-items v-if="currentUser" class="ml-auto d-flex align-center">
      <v-menu offset-y left rounded transition="slide-y-transition" :elevation="3" min-width="200px">
        <template v-slot:activator="{ on }">
          <v-avatar
            color="primary"
            size="40"
            v-on="on"
            class="account-avatar"
          >
            <template v-if="userInitials">
              <span class="white--text">{{ userInitials }}</span>
            </template>
            <template v-else>
              <v-icon large>mdi-account-circle-outline</v-icon>
            </template>
          </v-avatar>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar size="40" color="primary">
                <span class="white--text">{{ userInitials }}</span>
              </v-avatar>
              <h3 class="mt-2">{{ currentUser.displayName }}</h3>
              <p class="text-caption mt-1">
                {{ currentUser.email }}
              </p>
              <v-chip small class="mx-auto" :class="chipClass" dark>
                {{ currentUser.emailVerified ? 'Verified' : 'Unverified' }}
                <v-icon right dark x-small>mdi-check-decagram</v-icon>
              </v-chip>
              <v-divider class="mt-2"></v-divider>
              <v-list rounded density="compact">
                <v-list-item v-for="(item, index) in accountItems" :key="index" :to="getLogoutRoute(item)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>


<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import AlgoliaSearch from '@/components/search/AlgoliaSearch';
import GeneralReport from './dialogs/GeneralReport.vue';

export default {
  props: {
    toggleSidebar: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      selectedIcon: '/',
      appTitle: 'TIER',
      notAuthItems: [
        { title: 'Sign Up', path: '/signup', icon: 'face' },
        { title: 'Sign In', path: '/login', icon: 'lock_open' },
      ],
      menuItems: [
        { title: 'Home', path: '/', icon: 'mdi-home-outline' },
        { title: 'Parameters', path: '/settings/menu', icon: 'mdi-cog-outline' },
        { title: 'Dashboard', path: '/dashboard/menu', icon: 'mdi-view-dashboard-outline' },
      ],
      commonItems: [
        { title: 'Cases', path: '/explore-cases', icon: 'mdi-briefcase-variant-outline' },
        { title: 'Laws', path: '/explore-laws', icon: 'mdi-bank-outline' },
      ],
      listItems: [
        { title: 'Users', path: '/user-list', icon: 'mdi-account-group-outline' },
        { title: 'Draft Cases', path: '/draft-list', icon: 'mdi-pencil-outline' },
        { title: 'Published Cases', path: '/case-list', icon: 'mdi-briefcase-variant-outline' },
        { title: 'Published Laws', path: '/laws', icon: 'mdi-bank-outline'},
      ],
      contributeItems: [
        { title: 'Draft Cases', path: '/draft-list', icon: 'mdi-pencil-outline' },
        { title: 'Published Cases', path: '/case-list', icon: 'mdi-briefcase-variant-outline' },
        { title: 'Published Laws', path: '/laws', icon: 'mdi-bank-outline'},
      ],
      accountItems: [
        { title: 'Profile', path: 'profile', icon: 'mdi-account-cog-outline' },
        { title: 'Logout', path: 'logout', icon: 'mdi-logout-variant' },
      ],
    };
  },
  computed: {
    ...mapState(['currentUser', 'userClaims', 'userProfile']),
    ...mapGetters(['darkTheme']),
    isUserAuthenticated() {
      return this.currentUser && this.currentUser.emailVerified;
    },
    userInitials() {
      if (!this.currentUser.displayName) return 'U';
      
      // Titles to be excluded
      const titles = ['Atty.', 'Mr.', 'Mrs.', 'Engr.', 'Dr.'];

      // Split the display name into parts
      const nameParts = this.currentUser.displayName.split(' ');

      // Filter out titles
      const filteredNameParts = nameParts.filter(part => !titles.includes(part));

      // If no name parts left after filtering, return 'U'
      if (filteredNameParts.length === 0) return 'U';

      // Create initials from the first and last name parts
      let initials = filteredNameParts[0][0];
      if (filteredNameParts.length > 1) {
        initials += filteredNameParts[filteredNameParts.length - 1][0];
      }

      return initials.toUpperCase();
    },
    shouldDisplayContribute() {
      const role = this.userProfile.role;
      const notInTier = !(role === 'Tier1' || role === 'Tier2' || role === 'Tier3' || role === 'Tier4');
      return notInTier && this.isUserAuthenticated;
      // return !this.userClaims.admin && this.isUserAuthenticated;
    },
    btnClass() {
      return {
        'x-small': this.$vuetify.breakpoint.smAndDown,
        'hidden-sm-and-down': !this.$vuetify.breakpoint.smAndDown,
        'hidden-md-and-up': this.$vuetify.breakpoint.smAndDown,
      };
    },
    chipClass() {
      return this.currentUser.emailVerified ? 'success' : 'error';
    },
  },
  methods: {
    ...mapActions(['toggleTheme']),
    getLogoutRoute(item) {
      if (item && item.title === 'Logout' && this.currentUser) {
        return { name: item.path, params: { id: this.currentUser.uid } };
      } else if (item && item.title) {
        return { name: item.path };
      } else if (item && item.path) {
        return item.path;
      }
      return '/';
    },
    navLinkClass(path) {
      return {
        'active-link': this.$route.path === path
      };
    },
  },
  components: {
    AlgoliaSearch,
    GeneralReport
  },
};
</script>

<style scoped>
.rotate-chevron {
  transform: rotate(180deg); 
  transition: transform 0.3s ease; 
}

.nav-link-group {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.nav-link {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  margin: 0 15px;
  display: flex;
  align-items: center;
  position: relative;
}

.nav-link:hover {
  color: #5980a7;
}

.nav-link span {
  margin-left: 5px;
}

.nav-link::after {
  content: '';
  display: block;
  height: 2px;
  background-color: #7FA1C3;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: scaleX(0);
  transform-origin: left;
}

.nav-link:hover::after,
.nav-link.active-link::after {
  opacity: 1;
  transform: scaleX(1);
}

.theme-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #2c3e50;
  margin-right: 0.5rem;
}

.account-avatar {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
