import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
const fb = require('./firebaseDb');
import Vuetify from 'vuetify';
import VueResource from 'vue-resource';
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import './styles/common.css';
import InstantSearch from 'vue-instantsearch';
import './registerServiceWorker'

Vue.use(Vuetify);
Vue.use(InstantSearch);
Vue.use(VueResource);
Vue.config.productionTip = false;

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#006064',
        secondary: '#78909C',
        accent: '#82B1FF',
        error: '#E96479',
        success: '#90B77D',
        info: '#2196F3',
        warning: '#FFC107',
        base: '#8CB9BD'
      },
      dark: {
        primary: '#00838F',
        secondary: '#546E7A',
        accent: '#03DAC6',
        error: '#E96479',
        success: '#90B77D',
        info: '#2196F3',
        warning: '#FB8C00',
        background: '#121212'
      },
    },
  },
});

store.watch(
  (state) => state.darkTheme,
  (newVal) => {
    vuetify.framework.theme.dark = newVal;
    const themeClass = newVal ? 'dark' : 'light';
    document.body.className = themeClass;
  }
);

let app;
fb.auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      vuetify,
      render: h => h(App),
      created() {
        const themeClass = this.$store.state.darkTheme ? 'dark' : 'light';
        document.body.className = themeClass;
      }
    });
  }
});
