<template>
  <div>
    <!-- online -->
    <ais-instant-search v-if="isOnline" :search-client="searchClient" index-name="cases_prod">
      <ais-autocomplete>
        <template v-slot="{ indices, refine }">
          <v-chip
            v-if="!isHomePage && !isSearchResultPage"
            label
            outlined
            @click="openDialog"
            color="primary"
          >
            <v-icon left>mdi-magnify</v-icon>
            <span class="font-weight-light">Search Cases</span>
          </v-chip>
          

          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-card
                class="rounded-xxl search-dialog-card"
                v-if="dialog"
                :style="[borderStyle, cardStyle]"
              >
                <v-text-field
                  ref="searchInput"
                  v-model="searchQuery"
                  placeholder="Looking for..."
                  @input="refine"
                  @focus="showAutoComplete = true"
                  v-click-outside="handleCloseModal"
                  @keydown.enter="handleEnterKey"
                  prepend-inner-icon="mdi-magnify"
                  color="darkTheme"
                  solo
                  rounded
                  flat
                  clearable
                  hide-details
                  full-width
                />

                <v-expand-transition>
                  <div v-show="showAutoComplete">
                    <v-card>
                      <v-list
                        v-if="searchQuery && indices.some((index) => index.hits.length)"
                        :class="darkTheme ? 'dark-theme-list' : 'light-theme-list'"
                      >
                        <div v-for="index in indices" :key="index.name">
                          <div v-for="hit in limitedHits(index.hits)" :key="hit.objectID">
                            <v-list-item
                              v-for="ref in hit.references"
                              :key="ref.refno"
                              :to="{ name: 'case', params: { id: hit.objectID } }"
                              @click="dialog = false"
                            >
                              <v-list-item-icon class="ml-3">
                                <v-img
                                  contain
                                  v-if="faviconImage || lightImage"
                                  aspect-ratio="1"
                                  alt="Protected"
                                  :src="logoImage"
                                ></v-img>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{ ref.refno }}</v-list-item-title>
                                <v-list-item-subtitle class="text--primary">
                                  {{ ref.short }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="hit.idx">
                                  {{ hit.idx }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action-text class="mr-3 d-none d-sm-flex">
                                {{ showDate(hit.date) }}
                              </v-list-item-action-text>
                            </v-list-item>
                          </div>
                        </div>
                      </v-list>
                      <v-list-item v-if="searchQuery && !indices.some((index) => index.hits.length)">
                        <v-list-item-content class="text-center">
                          <v-list-item-title><em>Case "{{ searchQuery }}" not found</em></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </ais-autocomplete>
    </ais-instant-search>
    <!-- offline -->
    <div v-else>
      <v-btn
        v-if="!isHomePage && !isSearchResultPage"
        icon
        class="mt-2"
        @click="openDialog"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card
            class="rounded-xxl search-dialog-card"
            v-if="dialog"
            :style="[borderStyle, cardStyle]"
          >
            <v-text-field
              ref="searchInput"
              v-model="searchQuery"
              placeholder="Looking for..."
              @input="refineOffline"
              @focus="showAutoComplete = true"
              v-click-outside="handleCloseModal"
              @keydown.enter="handleEnterKey"
              prepend-inner-icon="mdi-magnify"
              color="darkTheme"
              solo
              rounded
              flat
              clearable
              hide-details
              full-width
            />

            <v-expand-transition>
              <div v-show="showAutoComplete">
                <v-card>
                  <v-list
                    v-if="filteredOfflineHits.length"
                    :class="darkTheme ? 'dark-theme-list' : 'light-theme-list'"
                  >
                    <div v-for="hit in filteredOfflineHits" :key="hit.objectID">
                      <v-list-item
                        v-for="ref in hit.references"
                        :key="ref.refno"
                        :to="{ name: 'case', params: { id: hit.objectID } }"
                        @click="dialog = false"
                      >
                        <v-list-item-icon class="ml-3">
                          <v-img
                            contain
                            v-if="faviconImage || lightImage"
                            aspect-ratio="1"
                            alt="Protected"
                            :src="logoImage"
                          ></v-img>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ ref.refno }}</v-list-item-title>
                          <v-list-item-subtitle class="text--primary">
                            {{ ref.short }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="hit.idx">
                            {{ hit.idx }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action-text class="mr-3 d-none d-sm-flex">
                          {{ showDate(hit.date) }}
                        </v-list-item-action-text>
                      </v-list-item>
                    </div>
                  </v-list>
                  <v-list-item v-if="searchQuery && !filteredOfflineHits.length">
                    <v-list-item-content class="text-center">
                      <v-list-item-title><em>Case "{{ searchQuery }}" not found</em></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import moment from "moment";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import useLocalStorage from '@/composables/useLocalStorage'
import Fuse from "fuse.js";

export default {
  data() {
    return {
      isOnline: navigator.onLine,
      searchQuery: "",
      dialog: false,
      showAutoComplete: false,
      searchClient: algoliasearch(
        "4Z44T2TZTE",
        "afd9d28e0bc4c31b52e8dc5709077153"
      ),
      selectedTier: "Tier4",
      faviconImage: null,
      lightImage: null,
      maxHits: 10, 
    };
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  mounted() {
    this.loadOfflineHits(); // Load offline hits from Vuex/localStorage
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  computed: {
    ...mapGetters(["darkTheme"]),
    ...mapState(["offlineHits", "userProfile"]),
    isHomePage() {
      return this.$route.path === "/";
    },
    isSearchResultPage() {
      return this.$route.path === "/search-result";
    },
    borderStyle() {
      return {
        border: `1px solid ${this.$vuetify.theme.themes.light.secondary}`,
      };
    },
    cardStyle() {
      return {
        maxWidth: this.$vuetify.breakpoint.mdAndUp ? '50%' : '80%',
      };
    },
    limitedHits() {
      return (hits) => hits.slice(0, this.maxHits);
    },
    filteredOfflineHits() {
      if (this.searchQuery.trim() === "") return [];
      
      const options = {
        keys: [
          { name: 'references.refno', weight: 0.4 }, // Adjust weight as needed
          { name: 'references.short', weight: 0.3 },
          { name: 'idx', weight: 0.3 },
        ],
        threshold: 0.2, // Lower values for stricter matching, closer to Algolia's behavior
        shouldSort: true, // Sort by relevance
        includeScore: true, // Includes search score if you want to use it
      };

      const fuse = new Fuse(this.offlineHits, options);
      const results = fuse.search(this.searchQuery);

      return results.map(result => result.item);
    },
    logoImage() {
      return this.$vuetify.theme.dark ? this.lightImage : this.faviconImage;
    },
  },
  created() {
    const faviconImageStorage = useLocalStorage(null, "faviconImage");
    const lightIconStorage = useLocalStorage(null, "lightImage");
    this.faviconImage = faviconImageStorage.get();
    this.lightImage = lightIconStorage.get();
    this.setSelectedTier();
  },
  methods: {
    ...mapMutations(['setOfflineHits']),
    ...mapActions(['loadOfflineHits', 'saveOfflineHits']),
    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },

    handleOfflineSearch(event) {
      if (this.searchQuery.trim() !== "") {
        this.showAutoComplete = true;
        if (event && event.key === 'Enter') {
          this.handleEnterKey(this.searchQuery);
        }
      } else {
        this.showAutoComplete = false;
      }
    },

    setSelectedTier() {
      if (this.userProfile && this.userProfile.role) {
        switch (this.userProfile.role) {
          case "admin":
            this.selectedTier = "Tier4";
            break;
          case "contributor":
            this.selectedTier = "Tier4";
            break;
          case "Tier1":
            this.selectedTier = "Tier1";
            break;
          case "Tier2":
            this.selectedTier = "Tier2";
            break;
          case "Tier3":
            this.selectedTier = "Tier3";
            break;
          case "Tier4":
            this.selectedTier = "Tier4";
            break;

          default:
            this.selectedTier = "Tier4";
        }
      } else {
        this.selectedTier = "Tier4";
      }
    },
    filteredHits(hits) {
      return hits.filter(hit => hit.tiers && hit.tiers.includes(this.selectedTier));
    },
    showDate(v) {
      return moment.unix(v).format("LL");
    },
    handleEnterKey() {
      this.resultPage(this.searchQuery);
      this.dialog = false;
      this.clearTextField();
    },
    resultPage(refValue) {
      if (!this.isSearchResultPage) {
        this.$store.commit("setValue", refValue);
        this.$router.push("/search-result");
        this.clearTextField();
      }
    },
    clearTextField() {
      this.searchQuery = "";
    },
    handleCloseModal() {
      this.showAutoComplete = false;
      this.dialog = false;
      this.clearTextField();
    },
    openDialog() {
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
  },
};
</script>

<style scoped>
.search-dialog-card {
  position: fixed;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
}

.v-list-item {
  cursor: pointer;
}

.v-list-item:hover {
  background-color: rgba(31, 24, 24, 0.1);
}

.search-toolbar {
  padding: 0;
}

.search-toolbar .v-text-field {
  margin: 0;
  flex: 1;
}

.search-toolbar .v-btn {
  margin-left: 8px;
}
</style>
