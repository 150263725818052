import Vue from 'vue';
import Vuex from 'vuex';
import { getLocalStorageItem, setLocalStorageItem } from './composables/useLocalStorageHelper.js';
const fb = require('./firebaseDb.js');
import { lawsCollection, sectionsCollection } from './firebaseDb.js';

Vue.use(Vuex);

// handle page reload
fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setCurrentUser', user);
    store.dispatch('fetchUserProfile');
    store.dispatch('fetchUserClaims');

    const unsubUserProf = fb.usersCollection.doc(user.uid).onSnapshot(doc => {
      store.commit('setUserProfile', doc.data());
    }, error => {
      console.log('profile snapshot: ' + error);
    });
    store.commit('setListenerUserProf', unsubUserProf);
  }
});

export const store = new Vuex.Store({
  state: {
    currentUser: null,
    userClaims: {
      admin: null,
    },
    listener: {
      userProf: null,
      userColl: null,
      caseColl: null,
      draftColl: null,
    },
    userProfile: {},
    users: [],
    cases: [],
    drafts: [],
    laws: [],
    settings: [],
    myValue: '',
    darkTheme: getLocalStorageItem('theme', 'light') === 'dark',
    offlineHits: [],
    searchValue: '',
  },
  actions: {
    detachDraftList({ commit }) {
      commit('unsubDraft');
      commit('setDrafts', null);
    },
    detachCaseList({ commit }) {
      commit('unsubCase');
      commit('setCases', null);
    },
    detachUserList({ commit }) {
      commit('unsubUser');
      commit('setOtherUsers', null);
    },
    unsubscribe({ commit }) {
      commit('unsubListeners');
    },
    clearData({ commit }) {
      commit('setCurrentUser', null);
      commit('setUserClaims', {});
      commit('setUserProfile', {});
      commit('setOtherUsers', null);
      commit('setCases', null);
      commit('setDrafts', null);
      commit('setSettings', null);
    },
    async fetchLaws({ commit }) {
      try {
        const querySnapshot = await lawsCollection.get();
        const laws = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        commit("setLaws", laws);
      } catch (error) {
        console.error("Error fetching laws:", error);
      }
    },
    async deleteLaw({ dispatch }, lawId) {
      try {
        const lawDoc = await lawsCollection.doc(lawId).get();
        if (!lawDoc.exists) {
          throw new Error("Law not found");
        }
        const lawData = lawDoc.data();
  
        // Delete sections related to the law
        if (lawData.contents && lawData.contents.length > 0) {
          await dispatch("deleteSectionsFromContents", lawData.contents);
        }
  
        // Delete the law itself
        await lawsCollection.doc(lawId).delete();
  
        // Fetch updated list of laws
        dispatch("fetchLaws");
      } catch (error) {
        console.error("Error deleting law:", error);
      }
    },
    async deleteSectionsFromContents(_, contents) {
      for (const tier of contents) {
        // Delete sections in top-level listItems
        if (tier.listItems && tier.listItems.length > 0) {
          for (let listGroup of tier.listItems) {
            if (listGroup.id) {
              await sectionsCollection.doc(listGroup.id).delete();
            }
          }
        }
        // Recursively delete sections in subTiers
        if (tier.subTiers && tier.subTiers.length > 0) {
          await this.deleteSectionsFromContents(tier.subTiers);
        }
      }
    },
    fetchUserProfile({ commit, state }) {
      fb.usersCollection.doc(state.currentUser.uid).get().then(res => {
        commit('setUserProfile', res.data());
      }).catch(err => {
        console.log('fetchUserProfile: ' + err);
      });
    },
    fetchUserClaims({ commit, state }) {
      state.currentUser.getIdTokenResult().then((res) => {
        commit('setUserClaims', res.claims);
        const token = res.token;
        localStorage.setItem('userToken', token);
        // console.log(res.claims);
        // console.log(res);
      }).catch(err => {
        console.log('fetchUserClaims: ' + err);
      });
    },
    fetchSettings({ commit }) {
      fb.settingsCollection.get().then(querySnapshot => {
        let settingsArray = [];
        querySnapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          settingsArray.push(data);
        });
        commit('setSettings', settingsArray);
      }).catch(err => {
        console.log('fetchSettings: ' + err);
      });
    },
    streamUserLists({ commit }) {
      const unsubUserCollection = fb.usersCollection.onSnapshot(querySnapshot => {
        let usersArray = [];
        querySnapshot.forEach(doc => {
          let otherUser = doc.data();
          otherUser.id = doc.id;
          usersArray.push(otherUser);
        });
        commit('setOtherUsers', usersArray);
      }, error => {
        console.log('users snapshot: ' + error);
      });
      commit('setListenerUserColl', unsubUserCollection);
    },
    streamCaseLists({ commit }) {
      const unsubCaseCollection = fb.casesCollection.onSnapshot(querySnapshot => {
        let casesArray = [];
        querySnapshot.forEach(doc => {
          let caseData = doc.data();

          if (typeof caseData.references !== 'undefined') {
            if (caseData.references.length == 1) {
              caseData.caseId = caseData.references[0].refno;
            } else {
              caseData.caseId = caseData.references[0].refno + '...';
            }
            caseData.title = caseData.references[0].short;
          } else {
            // backwards compatibility
            // need to remove after data has been changed
            caseData.caseId = doc.id;
          }
          caseData.id = doc.id;
          if (caseData.status === null) { caseData.status = ''; }
          if (caseData.published == null) { caseData.published = false; }
          casesArray.push(caseData);
        });
        commit('setCases', casesArray);
      }, error => {
        console.log('cases snapshot: ' + error);
      });
      commit('setListenerCaseColl', unsubCaseCollection);
    },
    streamCaseListsFiltered({ commit }, val) {
      const unsubCaseCollection = fb.casesCollection.onSnapshot(querySnapshot => {
        let casesArray = [];
        querySnapshot.forEach(doc => {
          if (doc.id != val.id) {
            let caseData = {};
            caseData.references = doc.data().references;

            if (typeof caseData.references != 'undefined') {
              caseData.references.forEach((e, index) => {
                if (index != 0) {
                  caseData.caseId += ';' + e.refno;
                } else {
                  caseData.caseId = e.refno;
                }
              });
            }
            // for the dropdown
            caseData.text = caseData.caseId;
            caseData.value = doc.id;

            caseData.id = doc.id;
            caseData.issues = doc.data().issues;
            casesArray.push(caseData);
          }
        });
        commit('setCases', casesArray);
      }, error => {
        console.log('cases snapshot: ' + error);
      });
      commit('setListenerCaseColl', unsubCaseCollection);
    },
    streamDraftLists({ commit, state }) {
      const unsubDraftCollection = fb.draftsCollection.onSnapshot(querySnapshot => {
        let casesArray = [];
        querySnapshot.forEach(doc => {
          let caseData = doc.data();

          // checking for undefined because some old data do not have the references array
          if (typeof caseData.references !== 'undefined') {
            if (caseData.references.length == 1) {
              caseData.caseId = caseData.references[0]?.refno;
            } else {
              caseData.caseId = caseData.references[0]?.refno + '...';
            }
            caseData.title = caseData.references[0]?.short;
          }

          caseData.value = fb.db.doc('drafts/' + doc.id);
          caseData.id = doc.id;

          if (caseData.status === null) { caseData.status = ''; }
          if (state.userClaims.admin) {
            casesArray.push(caseData);
          } else if (state.currentUser.uid === caseData.author) {
            casesArray.push(caseData);
          }
        });
        commit('setDrafts', casesArray);
      }, error => {
        console.log('draft snapshot: ' + error);
      });
      commit('setListenerDraftColl', unsubDraftCollection);
    },
    toggleTheme({ commit, state }) {
      commit('setTheme', !state.darkTheme);
    },
    loadOfflineHits({ commit }) {
      const offlineHits = localStorage.getItem('offlineHits') ? JSON.parse(localStorage.getItem('offlineHits')) : [];
      commit('setOfflineHits', offlineHits);
    },
    saveOfflineHits({ commit }, hits) {
      const existingHits = JSON.parse(localStorage.getItem('offlineHits')) || [];
    
      const normalizedHits = hits.map(hit => {
        // Normalize the structure
        if (hit.references && Array.isArray(hit.references) && hit.references.length > 0) {
          // Extract the first reference (you can adjust this logic as needed)
          const reference = hit.references[0];
          return {
            ...hit,
            refno: reference.refno || hit.refno,
            short: reference.short || hit.short,
            title: reference.title || hit.title,
          };
        } else {
          return hit;
        }
      });
    
      const allHits = [...existingHits, ...normalizedHits].reduce((acc, current) => {
        const x = acc.find(item => item.objectID === current.objectID);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    
      localStorage.setItem('offlineHits', JSON.stringify(allHits));
      commit('setOfflineHits', allHits);
    }    
  },
  mutations: {
    setLaws(state, laws) {
      state.laws = laws;
    },
    setCurrentUser(state, val) {
      state.currentUser = val;
    },
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setUserClaims(state, val) {
      state.userClaims = val;
    },
    setOtherUsers(state, val) {
      if (val) {
        state.users = val;
      } else {
        state.users = [];
      }
    },
    setCases(state, val) {
      if (val) {
        state.cases = val;
      } else {
        state.cases = [];
      }
    },
    setDrafts(state, val) {
      if (val) {
        state.drafts = val;
      } else {
        state.drafts = [];
      }
    },
    setSettings(state, val) {
      if (val) {
        state.settings = val;
      } else {
        state.settings = [];
      }
    },
    setListenerUserProf(state, val) {
      state.listener.userProf = val;
    },
    setListenerUserColl(state, val) {
      state.listener.userColl = val;
    },
    setListenerCaseColl(state, val) {
      state.listener.caseColl = val;
    },
    setListenerDraftColl(state, val) {
      state.listener.draftColl = val;
    },
    unsubListeners(state) {
      if (state.listener.userColl) {
        state.listener.userColl();
      }
      if (state.listener.caseColl) {
        state.listener.caseColl();
      }
      if (state.listener.draftColl) {
        state.listener.draftColl();
      }
      if (state.listener.userProf) {
        state.listener.userProf();
      }
    },
    unsubDraft(state) {
      state.listener.draftColl();
    },
    unsubCase(state) {
      state.listener.caseColl();
    },
    unsubUser(state) {
      state.listener.userColl();
    },
    setValue(state, payload) {
      state.myValue = payload;
    },
    setTheme(state, payload) {
        state.darkTheme = payload;
        setLocalStorageItem('theme', payload ? 'dark' : 'light');
    },
    setOfflineHits(state, hits) {
      state.offlineHits = hits; 
    },
    setSearchValue(state, value) {
      state.searchValue = value; 
    },
  },
  getters: {
    darkTheme: state => state.darkTheme,
    getLaws: (state) => state.laws,
    offlineHits: (state) => state.offlineHits,
    searchValue: (state) => state.searchValue,
  },
});
