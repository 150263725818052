<template>
  <v-footer dark padless fixed>
    <campaignText v-if="currentUser && userProfile.role === 'Tier4'" :message="infoMessage" type="info" />
    <v-card class="flex" color="#686D76" flat tile>
      <v-card-text class="py-2 text-center">
        {{ currentYear }} — <strong>TIER</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import campaignText from './utils/campaignText.vue';

export default {
  data() {
    return {
      infoMessage: "✳  You are currently browsing the TIER website for free in beta, this will incur a fee later -  BETA TEST ✳;",
    }
  },
  computed: {
    ...mapGetters(['darkTheme']),
    ...mapState(['currentUser', 'userProfile']),
    currentYear() {
      return new Date().getFullYear();
    },
  },
  components: {
    campaignText,
  }
};
</script>