<template>
    <div class="pa-4 text-center">
      <!-- Dialog for reporting an issue -->
      <v-dialog v-model="dialog" max-width="500">
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on">
            <v-icon>mdi-alert-octagon-outline</v-icon>
          </v-btn>
        </template>
  
        <v-card rounded="lg">
          <v-card-title class="d-flex justify-space-between align-center">
            <div class="text-h5 text-medium-emphasis ps-2">
              Report an Issue
            </div>
  
            <v-btn icon text @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
  
          <v-divider class="mb-4"></v-divider>
  
          <v-card-text>
            <div class="text-medium-emphasis mb-4">
              Your feedback helps us improve your experience. Please provide details about the issue you encountered, including any steps that may help us reproduce it.
            </div>
  
            <v-textarea
              v-model="message"
              :counter="500"
              class="mb-2"
              rows="5"
              outlined
              persistent-counter
            ></v-textarea>
  
            <div class="text-medium-emphasis mb-1">
              Thanks for taking the time to let us know. Your input is really valuable in helping us make things better for everyone.
            </div>
          </v-card-text>
  
          <v-divider class="mt-2"></v-divider>
  
          <v-card-actions class="my-2 d-flex justify-end">
            <v-btn color="white" class="text-none" rounded @click="dialog = false">Cancel</v-btn>
            <v-btn 
              class="text-none" 
              color="primary" 
              rounded 
              @click="submitReport" 
              :disabled="!message"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Confirmation dialog after report submission -->
      <v-dialog v-model="confirmationDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h5">Report Submitted</v-card-title>
  
          <v-card-text>
            Your report has been submitted successfully. Thank you for your feedback!
          </v-card-text>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="confirmationDialog = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { db } from '../../firebaseDb';
  import { mapState } from 'vuex';
  
  export default {
    data() {
      return {
        dialog: false,
        confirmationDialog: false,
        message: '',
        author: '',
      };
    },
    computed: {
      ...mapState(["currentUser", "userProfile"]),
    },
    created() {
      this.author = this.currentUser ? this.currentUser.uid : '';
    },
    methods: {
      async submitReport() {
        try {
          // Save the report to Firestore
          await db.collection("reports").add({
            user: "users/" + this.author,
            userReport: [
              {
                caseRef: '',
                reportParam: '',
                reportTopic: 'General Report',
                reportDetails: this.message,
                reportIsActive: true,
              }
            ],
          });
  
          // Open confirmation dialog
          this.confirmationDialog = true;
  
          // Clear form and close the original dialog
          this.clearForm();
        } catch (error) {
          console.error("Error submitting report: ", error);
        }
      },
      clearForm() {
        this.message = "";
        this.dialog = false;  // Close the original dialog
      },
    },
  };
  </script>
  
  <style scoped>
  .text-none {
    text-transform: none;
  }
  </style>
  