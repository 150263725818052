export function getLocalStorageItem(key, defaultValue) {
  const storedValue = localStorage.getItem(key);
  if (storedValue) {
    try {
      return JSON.parse(storedValue);
    } catch (e) {
      return storedValue;
    }
  }
  // console.log(defaultValue);
  return defaultValue;
}

  
export function setLocalStorageItem(key, value) {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
}
  