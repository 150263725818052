import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../store'

Vue.use(VueRouter)


const routes = [
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../components/login/Signup.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/login/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../components/login/ForgotPassword.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../components/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user-list',
    name: 'user-list',
    component: () => import('../components/user/UserList'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/user-edit/:id',
    name: 'user-edit',
    component: () => import('../components/user/UserEdit'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/user-data',
    name: 'user-data',
    component: () => import('../components/user/UserHighlights'),
    meta: { requiresAuth: true }
  },
  {
    path: '/settings/subscription',
    name: 'subscription',
    component: () => import('../components/user/UserSettingsSubscription'),
    meta: { requiresAuth: true }
  },
  {
    path: '/settings/profile',
    name: 'profile',
    component: () => import('../components/user/UserSettingsMenu'),
    meta: { requiresAuth: true }
  },
  {
    path: '/settings/menu',
    name: 'settings-menu',
    component: () => import('../components/settings/SettingsMenu'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/case-list',
    name: 'case-list',
    component: () => import('../components/case/CaseList'),
    meta: { requiresAuth: true, requiresContributor: true }
  },
  {
    path: '/report-list',
    name: 'report-list',
    component: () => import('../components/case/ReportList'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/draft-list',
    name: 'draft-list',
    component: () => import('../components/case/CaseDraft'),
    meta: { requiresAuth: true, requiresContributor: true }
  },
  {
    path: '/case-edit/:draft?/:id',
    name: 'case-edit',
    component: () => import('../components/case/CaseEdit'),
    meta: { requiresAuth: true, requiresContributor: true }
  },
  {
    path: '/case/:id',
    name: 'case',
    component: () => import('../components/case/CaseView'),
    meta: { requiresAuth: true, requiresVerify: true }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../components/login/Logout'),
    meta: { requiresAuth: true }
  },
  {
    path: '/search-result',
    name: 'search-result',
    component: () => import('../components/search/SearchPage'),
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboard/menu',
    name: 'dashboard',
    component: () => import('../components/dashboard/DashboardMenu'),
    meta: { requiresAuth: true, requiresContributor: true },
  },
  // law view
  {
    path: '/laws-and-regulations/:id',
    name: 'laws-and-regulations',
    component: () => import('../components/laws/LawView.vue'),
    meta: { requiresAuth: true, requiresVerify: true }
  },
  // law list
  {
    path: '/laws',
    name: 'laws-list',
    component: () => import('../components/laws/LawList.vue'),
    meta: { requiresAuth: true, requiresContributor: true }
  },
  {
    path: '/laws-edit/:id',
    name: 'laws-edit',
    component: () => import('../components/laws/LawEdit.vue'),
    meta: { requiresAuth: true, requiresContributor: true }
  },
  {
    path: '/laws-edit/:id/contents',
    name: 'law-editor',
    component: () => import('../components/laws/LawEdit.vue'),
    meta: { requiresAuth: true, requiresContributor: true },
  },
  {
    path: '/explore-cases',
    name: 'explore-cases',
    component: () => import('../components/case/CasePage.vue'),
    meta: { requiresAuth: true, requiresVerify: true }
  },
  {
    path: '/explore-laws',
    name: 'explore-laws',
    component: () => import('../components/laws/LawPage.vue'),
    meta: { requiresAuth: true, requiresVerify: true }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../components/login/ResetPassword.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      setTimeout(() => {
        const element = document.getElementById(to.hash.replace(/#/, ''))
        if (element && element.scrollIntoView) {
          element.scrollIntoView({ block: 'end', behavior: 'smooth' })
        }
      }, 500)
      return { selector: to.hash }
      //Or for Vue 3:
      //return {el: to.hash}
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  //const requiresVerify = to.matched.some(x => x.meta.requiresVerify)
  const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin)
  const requiresContributor = to.matched.some(x => x.meta.requiresContributor)

  if (requiresAuth && !store.state.currentUser) {
    next('/login')
  } else if (to.path === '/login' && store.state.currentUser) {
    window.location.href = '/';
  }else if (requiresAdmin && store.state.userClaims.admin != 1) {
    // not allowed
  } else if (requiresContributor && store.state.userClaims.admin == null) {
    // not allowed
  } else if (requiresAuth && store.state.currentUser) {
    next()
  } else {
    next()
  }
})

export default router