<template>
  <v-navigation-drawer
    app
    v-model="localSidebar"
    class="hidden-md-and-up"
    temporary
    width="300px"
  >
    <v-list-item class="px-2">
      <v-spacer />
      <v-btn
        icon
        @click.stop="toggleSidebar"
      >
        <v-icon color="primary">mdi-chevron-double-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>
    <v-list rounded density="compact">
      <!-- User Avatar and Info -->
      <v-list-item three-line v-if="currentUser">
        <v-list-item-avatar>
          <v-avatar color="primary" size="40">
            <template v-if="userInitials">
              <span class="white--text">{{ userInitials }}</span>
            </template>
            <template v-else>
              <v-icon large>mdi-account-circle-outline</v-icon>
            </template>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ currentUser.displayName || 'User' }}</v-list-item-title>
          <v-list-item-subtitle>{{ currentUser.email || 'Email address' }}</v-list-item-subtitle>
          <v-subheader>
            <v-chip small class="ma-2" :class="chipClass" dark>
              {{ currentUser.emailVerified ? 'Verified' : 'Unverified' }}
              <v-icon right dark x-small>mdi-check-decagram</v-icon>
            </v-chip>
          </v-subheader>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="currentUser">
        <router-link :to="getLogoutRoute(accountItems[0])" class="no-underline">
          <v-btn plain small>
            <v-icon left>mdi-account-cog-outline</v-icon>
            <v-list-item-content>Profile</v-list-item-content>
          </v-btn>
        </router-link>
      </v-list-item>

      <v-divider class="mb-4"></v-divider>

      <!-- Menu Items for Admin Users -->
      <v-list-item-group v-if="userClaims.admin" class="hidden-md-and-up" color="primary">
        <v-list-item v-for="item in menuItems" :key="item.title" :to="item.path">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group class="hidden-md-and-up" color="primary" v-if="currentUser">
        <v-list-item v-for="item in commonItems" :key="item.title" :to="item.path">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <!-- List Items Menu for Admin Users -->
      <v-list v-if="userClaims.admin">
        <v-list-group no-action color="primary">
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>list</v-icon>
              </v-list-item-icon>
              <v-list-item-content>List</v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item v-for="(item, idx) in listItems" :key="idx" :to="item.path">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <!-- Contribute Items Menu for Authenticated Users -->
      <v-list v-if="shouldDisplayContribute && !userClaims.admin">
        <v-list-group color="primary">
          <template v-slot:activator>
            <v-list-item>
              <v-icon left>list</v-icon>
              <v-list-item-title>Lists</v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item v-for="(item, idx) in contributeItems" :key="idx" :to="item.path">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-list>
    <!-- Divider and Logout Button -->
    <template v-slot:append>
      <v-divider></v-divider>
      <v-list>
        <v-list-item v-if="currentUser">
          <v-list-item-content>
            <v-btn class="primary" rounded :to="getLogoutRoute(accountItems[1])">
              Logout
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    sidebar: {
      type: Boolean,
      required: true,
    },
    accountItems: {
      type: Array,
      required: true,
    },
    commonItems: {
      type: Array,
      required: true,
    },
    menuItems: {
      type: Array,
      required: true,
    },
    listItems: {
      type: Array,
      required: true,
    },
    contributeItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(['currentUser', 'userClaims', 'userProfile']),
    ...mapGetters(['darkTheme']),
    localSidebar: {
      get() {
        return this.sidebar;
      },
      set(value) {
        this.$emit('update:sidebar', value);
      }
    },
    isUserAuthenticated() {
      return this.currentUser && this.currentUser.emailVerified;
    },
    shouldDisplayContribute() {
      const role = this.userProfile.role;
      const notInTier = !(role === 'Tier1' || role === 'Tier2' || role === 'Tier3' || role === 'Tier4');
      return notInTier && this.isUserAuthenticated;
      // return !this.userClaims.admin && this.currentUser && this.currentUser.emailVerified;
    },
    userInitials() {
      if (!this.currentUser.displayName) return 'U';
      
      // Titles to be excluded
      const titles = ['Atty.', 'Mr.', 'Mrs.', 'Engr.', 'Dr.'];

      // Split the display name into parts
      const nameParts = this.currentUser.displayName.split(' ');

      // Filter out titles
      const filteredNameParts = nameParts.filter(part => !titles.includes(part));

      // If no name parts left after filtering, return 'U'
      if (filteredNameParts.length === 0) return 'U';

      // Create initials from the first and last name parts
      let initials = filteredNameParts[0][0];
      if (filteredNameParts.length > 1) {
        initials += filteredNameParts[filteredNameParts.length - 1][0];
      }

      return initials.toUpperCase();
    },
    chipClass() {
      return this.currentUser.emailVerified ? 'success' : 'error';
    },
  },
  methods: {
    toggleSidebar() {
      this.localSidebar = !this.localSidebar;
    },
    getLogoutRoute(item) {
      if (item && item.title === 'Logout' && this.currentUser) {
        return { name: item.path, params: { id: this.currentUser.uid } };
      } else if (item && item.title) {
        return { name: item.path };
      } else if (item && item.path) {
        return item.path;
      }
      return '/';
    },
  },
};
</script>
