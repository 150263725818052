<template>
  <v-app class="custom-font">
    <!-- AppBar -->
    <AppBar :toggle-sidebar="toggleSidebar" />

    <!-- Sidebar -->
    <Sidebar
      :sidebar.sync="sidebar"
      :account-items="accountItems"
      :menu-items="menuItems"
      :common-items="commonItems"
      :list-items="listItems"
      :contribute-items="contributeItems"
    />

    <!-- Main Content -->
    <v-main>
      <v-alert
        v-model="alert"
        dismissible
        text
        dense
        :type="alertType"
        :icon="alertIcon"
      >
        {{ message }}
      </v-alert>
      <adComponent v-if="currentUser" :userPlan="userProfile.role" />
      <v-container fluid fill-height>
        <v-layout justify-center pb-8>
          <router-view></router-view>
        </v-layout>
      </v-container>
    </v-main>

    <!-- Footer -->
    <Footer />
  </v-app>
</template>

<script>
import useLocalStorage from "@/composables/useLocalStorage";
import AppBar from './components/AppBar.vue';
import Sidebar from './components/Sidebar.vue';
import Footer from './components/Footer.vue';
import { mapGetters, mapState } from 'vuex';
import adComponent from "./components/ads/adComponent.vue";
import { messaging } from "./firebaseDb";

export default {
  data() {
    return {
      sidebar: false,
      accountItems: [
        { title: 'Profile', path: 'profile', icon: 'mdi-account-cog-outline' },
        { title: 'Logout', path: 'logout', icon: 'mdi-logout-variant' },
      ],
      menuItems: [
        { title: 'Home', path: '/', icon: 'mdi-home-outline' },
        { title: 'Parameters', path: '/settings/menu', icon: 'mdi-cog-outline' },
        { title: 'Dashboard', path: '/dashboard/menu', icon: 'mdi-view-dashboard-outline' },
      ],
      commonItems: [
        { title: 'Explore Cases', path: '/explore-cases', icon: 'mdi-briefcase-variant-outline' },
        { title: 'Laws', path: '/explore-laws', icon: 'mdi-bank-outline' },
      ],
      listItems: [
        { title: 'Users', path: '/user-list', icon: 'mdi-account-group-outline' },
        { title: 'Draft Cases', path: '/draft-list', icon: 'mdi-pencil-outline' },
        { title: 'Published Cases', path: '/case-list', icon: 'mdi-briefcase-variant-outline' },
        { title: 'Published Laws', path: '/laws', icon: 'mdi-bank-outline'},
      ],
      contributeItems: [
        { title: 'Draft Cases', path: '/draft-list', icon: 'mdi-pencil-outline' },
        { title: 'Published Cases', path: '/case-list', icon: 'mdi-briefcase-variant-outline' },
        { title: 'Published Laws', path: '/laws', icon: 'mdi-bank-outline'},
      ],
      caseImage: null,
      draftImage: null,
      lawImage: null,
      faviconImage: null,
      lightImage: null,
      alert: false,
      alertIcon: 'mdi-alert-circle',
      alertType: 'info',
      message: '',
    };
  },
  created() {
    const caseImageStorage = useLocalStorage(null, "caseImage");
    const draftImageStorage = useLocalStorage(null, "draftImage");
    const lawImageStorage = useLocalStorage(null, "lawImage");
    const faviconImageStorage = useLocalStorage(null, "faviconImage");
    const lightIconStorage = useLocalStorage(null, "lightImage");

    this.caseImage = caseImageStorage.get();
    this.draftImage = draftImageStorage.get();
    this.lawImage = lawImageStorage.get();
    this.faviconImage = faviconImageStorage.get();
    this.lightImage = lightIconStorage.get();

    if (!this.caseImage || !this.draftImage || !this.lawImage || !this.faviconImage || !this.lightImage) {
      this.saveResourceToLocalStorage(caseImageStorage, draftImageStorage, lawImageStorage, faviconImageStorage, lightIconStorage);
    }
    this.$vuetify.theme.dark = this.darkTheme;
  },
  computed: {
    ...mapGetters(['darkTheme']),
    ...mapState(["currentUser","userProfile"]),
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    saveResourceToLocalStorage(caseImageStorage, draftImageStorage, lawImageStorage, faviconImageStorage, lightIconStorage) {
      const resources = [
        { url: require('@/assets/draft.png'), storage: draftImageStorage },
        { url: require('@/assets/case.png'), storage: caseImageStorage },
        { url: require('@/assets/court.png'), storage: lawImageStorage },
        { url: require('@/assets/favicon.png'), storage: faviconImageStorage },
        { url: require('@/assets/lightIcon.png'), storage: lightIconStorage },
      ];

      resources.forEach(resource => {
        fetch(resource.url)
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resource.storage.set(reader.result);
              if (resource.storage === draftImageStorage) {
                this.draftImage = reader.result;
              } else if (resource.storage === caseImageStorage) {
                this.caseImage = reader.result;
              } else if (resource.storage === lawImageStorage) {
                this.lawImage = reader.result;
              } else if (resource.storage === faviconImageStorage) {
                this.faviconImage = reader.result;
              } else if (resource.storage === lightIconStorage) {
                this.lightImage = reader.result;
              }
            };
            reader.readAsDataURL(blob);
          });
      });
    },
    showOfflineMessage() {
      this.alert = true;
      this.alertType = 'error';
      this.alertIcon = 'mdi-wifi-off';
      this.message = "You are currently offline. Some features may not be available. Please check your internet connection";
      setTimeout(() => {
        this.alert = false;
      }, 5000);
    },
    showOnlineMessage() {
      this.alert = true;
      this.alertType = 'success';
      this.alertIcon = 'mdi-wifi-check';
      this.message = "You're back online!";
      setTimeout(() => {
        this.alert = false;
      }, 5000);
    },
  },
  mounted() {
    window.addEventListener('offline', this.showOfflineMessage);
    window.addEventListener('online', this.showOnlineMessage);
    messaging.requestPermission()
    .then(() => {
      // console.log('Notification permission granted.');
      return messaging.getToken();
    })
    .then(() => {
      //console.log('FCM Token:', token);
      // You can send this token to your backend to save for future use
    })
    .catch((error) => {
      console.error('Unable to get permission for notifications', error);
    });
  },
  beforeDestroy() {
    window.removeEventListener('offline', this.showOfflineMessage);
    window.removeEventListener('online', this.showOnlineMessage);
  },
  components: {
    AppBar,
    Sidebar,
    Footer,
    adComponent
  },
};
</script>

<style scoped>
.custom-font {
  font-family: 'Open Sans', sans-serif;
}
</style>
