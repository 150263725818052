<template>
  <div v-if="isFreeTier && adVisible" class="ad-container">
    <v-card flat width="750" height="100">
      <!-- Google AdSense Component -->
      <div class="ad-content">
        <ins class="adsbygoogle"
          style="display:inline-block;width:728px;height:90px;"
          data-ad-client="ca-pub-6054101315655925"
          data-ad-slot="4423396205"></ins>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AdComponent',
  props: {
    userPlan: {
      type: String,
      default: 'Tier4'
    },
  },
  data() {
    return {
      adVisible: false,  // Track ad visibility to prevent duplicate rendering
    };
  },
  computed: {
    isFreeTier() {
      return this.userPlan === 'Tier4';
    }
  },
  mounted() {
    if (window.adsbygoogle && !this.adVisible) {
      this.$nextTick(() => {
        try {
          window.adsbygoogle.push({});
          this.adVisible = true;  // Set flag to true after the ad is rendered
        } catch (e) {
          console.error("AdSense error:", e);
        }
      });
    }
  },
};
</script>

<style scoped>
.ad-container {
  display: flex;
  justify-content: center;
}

.ad-content {
  display: flex;
  justify-content: center; 
  align-items: center;  
  height: 100%;    
}
</style>
