import { render, staticRenderFns } from "./GeneralReport.vue?vue&type=template&id=676dcefe&scoped=true"
import script from "./GeneralReport.vue?vue&type=script&lang=js"
export * from "./GeneralReport.vue?vue&type=script&lang=js"
import style0 from "./GeneralReport.vue?vue&type=style&index=0&id=676dcefe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "676dcefe",
  null
  
)

export default component.exports