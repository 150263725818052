import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCccEWFNAsq6PPtTbuKjhpWu_YjZa9dSD8",
  authDomain: "tier-114a9.firebaseapp.com",
  databaseURL: "https://tier-114a9.firebaseio.com",
  projectId: "tier-114a9",
  storageBucket: "tier-114a9.appspot.com",
  messagingSenderId: "764742709548",
  appId: "1:764742709548:web:7859c07e7c36dfece6bc89",
  measurementId: "G-0PQYEBNLRD"
}

firebase.initializeApp(firebaseConfig);


const db = firebase.firestore()
const auth = firebase.auth()
const functions = firebase.functions()
const storage = firebase.storage()
const currentUser = auth.currentUser
const messaging = firebase.messaging();

// Enable Firestore offline persistence
db.enablePersistence({ synchronizeTabs: true })
.catch((err) => {
  if (err.code == 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled in one tab at a a time.
    console.error("Multiple tabs open, offline persistence can only be enabled in one tab at a time.");
  } else if (err.code == 'unimplemented') {
    // The current browser does not support all of the features required to enable persistence
    console.error("The current browser does not support all of the features required to enable persistence.");
  }
});

const usersCollection = db.collection('users')
const casesCollection = db.collection('cases')
const issuesCollection = db.collection('issues')
const draftsCollection = db.collection('drafts')
const settingsCollection = db.collection('settings')
const categoriesCollection = db.collection('categories')
const lawsCollection = db.collection('laws')
const termsCollection = db.collection('terms')
const sectionsCollection = db.collection('sections')

export {
  db, auth, currentUser, messaging, usersCollection, casesCollection, draftsCollection, issuesCollection, lawsCollection, sectionsCollection, settingsCollection, categoriesCollection, termsCollection, functions, storage
}